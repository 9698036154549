import { WebAd } from '~/ts/interfaces/types';
import { WebAdApi } from '~/ts/services/api/WebAdApi';

export class AdService {
  static onAdClick(ad?: WebAd | null) {
    if (!ad) return;

    const adApi = new WebAdApi();

    const url = /^(http|https):\/\//.test(ad.url) ? ad.url : `https://${ad.url}`;

    adApi.click(ad.uuid).then(() => {});
    window.open(url, '_blank');
  }
}
