import { AxiosRequestConfig, CancelTokenSource } from 'axios';
import config from '~/nuxt.config';
import { $axios } from '~/ts/utils/api';
import { DictionnaryType, PaginatedHydraResponse } from '~/ts/interfaces/types';

export default class Api<T = any> {
  readonly endpoint: string;
  private sources: DictionnaryType<CancelTokenSource> = {};

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  toJSON() {
    return { ...this };
  }

  create(payload: Object, config: AxiosRequestConfig | undefined = undefined): Promise<T> {
    return $axios.$post(`api/${this.endpoint}`, payload, config).catch(this.logError);
  }

  patch(entrypoint: string, payload: Object): Promise<any> {
    return $axios
      .$patch(entrypoint, payload, {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      })
      .catch(this.logError);
  }

  delete(entrypoint: string): Promise<T> {
    return $axios.$delete(entrypoint).catch(this.logError);
  }

  find(id: any, config?: AxiosRequestConfig, source?: CancelTokenSource | string): Promise<T> {
    source = this.cancelAndGetSource(source);
    return $axios.$get(id, { cancelToken: source?.token, ...config }).catch(this.logError);
  }

  findByUuid(uuid: string, config?: AxiosRequestConfig, source?: CancelTokenSource | string): Promise<T> {
    source = this.cancelAndGetSource(source);
    return $axios.$get(`/api/${this.endpoint}/${uuid}`, { cancelToken: source?.token, ...config }).catch(this.logError);
  }

  findAll(queryParams: any = {}, source?: CancelTokenSource | string): Promise<PaginatedHydraResponse<T>> {
    return this.customFindAll(`api/${this.endpoint}`, queryParams, source);
  }

  customFindAll(
    iri: string,
    queryParams: any = {},
    source?: CancelTokenSource | string,
  ): Promise<PaginatedHydraResponse<T>> {
    source = this.cancelAndGetSource(source);
    if (!('deletedAt' in queryParams) || !queryParams.deletedAt) {
      queryParams.deletedAt = 'false';
    }

    return $axios.$get(iri, { cancelToken: source?.token, params: queryParams }).catch(this.logError);
  }

  getEnumerationTypes(): Promise<any> {
    return $axios.$get(`api/enumerations/types`).catch(this.logError);
  }

  update(entrypoint: string, payload: Object): Promise<T> {
    return $axios.$put(entrypoint, payload).catch(this.logError);
  }

  formatParams(queryParams: Array<string> = [], params: DictionnaryType | Array<any>, prepend?: string): Array<string> {
    if (Array.isArray(params)) {
      if (!prepend) return queryParams;
      const key = `${prepend}[]`;

      params.forEach((value) => {
        if (Array.isArray(value) || typeof value === 'object') {
          return this.formatParams(queryParams, value, key);
        }

        queryParams.push(`${key}=${value ?? ''}`);
      });
    } else {
      Object.entries(params).forEach(([key, values]) => {
        if (prepend) key = `${prepend}[${key}]`;

        if (Array.isArray(values) || typeof values === 'object') {
          return this.formatParams(queryParams, values, key);
        }

        queryParams.push(`${key}=${values}`);
      });
    }

    return queryParams;
  }

  protected formatElasticQuery(filters: DictionnaryType, page: number, params: DictionnaryType): string {
    const queryParams = [];
    queryParams.push('page=' + page);

    this.formatParams(queryParams, filters, 'filters');
    this.formatParams(queryParams, params);

    return encodeURI(queryParams ? '?' + queryParams.join('&') : '');
  }

  protected logError(err: any) {
    try {
      // eslint-disable-next-line no-console
      if (config.dev) console.trace(this.endpoint, err);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (config.dev) console.trace(err);
    }

    throw err;
  }

  protected cancelAndGetSource(source?: CancelTokenSource | string): CancelTokenSource | undefined {
    if (!source && typeof source !== 'string') return;

    let key: string | undefined;
    if (typeof source === 'string') source = this.sources[(key = source)];

    if (source) source.cancel();

    source = $axios.CancelToken.source();
    if (key) this.sources[key] = source;

    return source;
  }
}
