import VueI18n from 'vue-i18n';
import { Component, Vue } from 'nuxt-property-decorator';
import { StringFormatterType } from '~/ts/interfaces/types';

@Component({
  components: {},
})
export class TextMixin extends Vue {
  formatClasses(text: string | VueI18n.TranslateResult, classes: string | Array<string>): string {
    if (typeof text !== 'string') text = text.toString();

    return text.replace(/\{([^}]+)}/g, (_match, p1) => {
      const className = (Array.isArray(classes) ? classes.shift() : classes) || '';
      return `<span class="${className}">${p1}</span>`;
    });
  }

  format(text: string | VueI18n.TranslateResult, formatters: StringFormatterType | Array<StringFormatterType>) {
    if (typeof text !== 'string') text = text.toString();

    return text.replace(/\{([^}]+)}/g, (_match, p1) => {
      const formatter: StringFormatterType =
        (Array.isArray(formatters) ? formatters.shift() : formatters) ?? ((str: string) => str);
      return formatter(p1);
    });
  }
}
