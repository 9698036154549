import locale5b317d55 from '../../translations/translations.ts'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr"},
  vueI18nLoader: false,
  locales: [{"code":"fr","file":"translations.ts","iso":"fr-FR","localeImg":"fr.svg","name":"Français"},{"code":"en","file":"translations.ts","iso":"en-GB","localeImg":"gb.svg","name":"Anglais"},{"code":"es","file":"translations.ts","iso":"es-ES","localeImg":"es.svg","name":"Espagnol"},{"code":"it","file":"translations.ts","iso":"it-IT","localeImg":"it.svg","name":"Italien"},{"code":"de","file":"translations.ts","iso":"de-DE","localeImg":"de.svg","name":"Allemand"},{"code":"nl","file":"translations.ts","iso":"nl-NL","localeImg":"nl.svg","name":"Néerlandais"},{"code":"pt","file":"translations.ts","iso":"pt-PT","localeImg":"pt.svg","name":"Portugais"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "translations/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":true,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://www.caramaps.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  seo: true,
  normalizedLocales: [{"code":"fr","file":"translations.ts","iso":"fr-FR","localeImg":"fr.svg","name":"Français"},{"code":"en","file":"translations.ts","iso":"en-GB","localeImg":"gb.svg","name":"Anglais"},{"code":"es","file":"translations.ts","iso":"es-ES","localeImg":"es.svg","name":"Espagnol"},{"code":"it","file":"translations.ts","iso":"it-IT","localeImg":"it.svg","name":"Italien"},{"code":"de","file":"translations.ts","iso":"de-DE","localeImg":"de.svg","name":"Allemand"},{"code":"nl","file":"translations.ts","iso":"nl-NL","localeImg":"nl.svg","name":"Néerlandais"},{"code":"pt","file":"translations.ts","iso":"pt-PT","localeImg":"pt.svg","name":"Portugais"}],
  localeCodes: ["fr","en","es","it","de","nl","pt"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "t",
  3: "r",
  4: "a",
  5: "n",
  6: "s",
  7: "l",
  8: "a",
  9: "t",
  10: "i",
  11: "o",
  12: "n",
  13: "s",
  14: ".",
  15: "t",
  16: "s",
  17: "\"",
  18: ":",
  19: "\"",
  20: ".",
  21: ".",
  22: "/",
  23: "t",
  24: "r",
  25: "a",
  26: "n",
  27: "s",
  28: "l",
  29: "a",
  30: "t",
  31: "i",
  32: "o",
  33: "n",
  34: "s",
  35: "/",
  36: "t",
  37: "r",
  38: "a",
  39: "n",
  40: "s",
  41: "l",
  42: "a",
  43: "t",
  44: "i",
  45: "o",
  46: "n",
  47: "s",
  48: ".",
  49: "t",
  50: "s",
  51: "\"",
  52: "}",
}

export const localeMessages = {
  'translations.ts': () => Promise.resolve(locale5b317d55),
}
