
import { Component } from 'nuxt-property-decorator';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';

@Component({
  components: {},
})
export default class Container extends TwMergeMixin {
  get defaultClass() {
    return 'flex w-full gap-4';
  }
}
