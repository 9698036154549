import { Component, Vue } from 'nuxt-property-decorator';

import { NuxtOptionsHead } from '@nuxt/types/config/head';
import { namespace } from 'vuex-class';
import nuxtConfig from '~/nuxt.config';
import { MetaProperty } from '~/ts/interfaces/types';

const metadata = namespace('metadata');

@Component({})
export default class MetadatasMixin extends Vue {
  @metadata.State
  title!: string;

  @metadata.State
  description!: string;

  @metadata.State
  keywords!: string | null;

  /**
   * Noms de routes à exclurer de l'indexe et des robots (si la route contient un de ces éléments)
   * @private
   */
  private excludePartialRoutes: Array<string> = ['activities', 'profile', 'substitute'];

  /**
   * Noms de route exacts à forcer dans l'indexe et les robots
   * @private
   */
  private includeRoutes: Array<string> = ['activities-map'];

  /**
   * Recupère et applique les métadatas de la page
   */
  head(): NuxtOptionsHead {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });

    const title = this.title;
    const description = this.description;

    const head: NuxtOptionsHead = {
      htmlAttrs: { ...i18nHead.htmlAttrs },
      link: [...i18nHead.link],
      meta: [...i18nHead.meta],
      title,
    };

    const meta: Array<MetaProperty> = [];

    const name = this.$route.name ? this.$route.name.split('___')[0] : null;
    if (!this.isIndexed(name)) {
      meta.push({ content: 'nofollow', hid: 'robots', name: 'robots' });
    }

    if (!head.script) {
      head.script = [];
    }

    if (name === 'login' || name === 'register') {
      head.script.push({ src: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js' });
    }

    meta.push(
      {
        content: title,
        hid: 'title',
        name: 'title',
      },
      {
        content: title,
        hid: 'og:title',
        property: 'og:title',
      },
      {
        content: description,
        hid: 'description',
        name: 'description',
      },
      {
        content: description,
        hid: 'og:description',
        property: 'og:description',
      },
      {
        content: process.env.clientBaseURL + this.$route.path,
        hid: 'og:url',
        property: 'og:url',
      },
    );

    if (this.keywords) {
      meta.push({
        content: this.keywords,
        hid: 'keywords',
        name: 'keywords',
      });
    }

    if (head.meta) head.meta = head.meta.concat(meta);

    return head;
  }

  isIndexed(name: string | null): boolean {
    if (nuxtConfig.preproduction) return false;
    if (name === null) return true;

    if (this.includeRoutes.includes(name)) return true;

    for (let i = 0; i < this.excludePartialRoutes.length; i++) {
      if (name.includes(this.excludePartialRoutes[i])) return false;
    }

    return true;
  }
}
