import { Plugin } from '@nuxt/types';

const accessor: Plugin = ({ store }, inject) => {
  inject('toast', {
    showMessage({ message = '', type = '' }) {
      store.commit('toast/showMessage', { message, type });

      const timeout = setTimeout(() => {
        store.commit('toast/setShow', false);
      }, Math.min(Math.max(message.length * 50, 5000), 10000));

      store.commit('toast/setIimeout', timeout);
    },
  });
};

export default accessor;
