import { CancelToken } from 'axios';
import Api from './Api';
import { WebAd, WebAdType } from '~/ts/interfaces/types';
import { $axios } from '~/ts/utils/api';
import { Entrypoints } from '~/ts/config/entrypoints';

export class WebAdApi extends Api {
  constructor() {
    super(Entrypoints.WebAd);
  }

  getAd(type: WebAdType, cancelToken: CancelToken | undefined = undefined): Promise<WebAd | undefined> {
    return $axios.$get(`api/${Entrypoints.WebAd}/current?type=${type}`, { cancelToken }).catch(this.logError);
  }

  click(uuid: string, cancelToken: CancelToken | undefined = undefined) {
    return $axios.$get(`api/${Entrypoints.WebAd}/${uuid}/click`, { cancelToken }).catch(this.logError);
  }
}
