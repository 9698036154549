import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { store } from './index';
import { DictionnaryType } from '~/ts/interfaces/types';

@Module({
  dynamic: true,
  name: 'user',
  namespaced: true,
  stateFactory: true,
  store,
})
export default class UserModule extends VuexModule {
  favorites: DictionnaryType<boolean> = {};

  @Mutation
  setFavorites(favorites: DictionnaryType<boolean>) {
    this.favorites = favorites;
  }

  @Mutation
  addFavorite(uuid: string) {
    this.favorites[uuid] = true;
  }

  @Mutation
  removeFavorite(uuid: string) {
    this.favorites[uuid] = false;
  }
}
