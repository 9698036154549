
import { Component, Vue } from 'nuxt-property-decorator';
import Container from '~/components/base/Container.vue';
import BaseIcon from '~/components/base/BaseIcon.vue';
import SubHeaderLink from '~/components/SubHeaderLink.vue';
@Component({
  components: { BaseIcon, Container, SubHeaderLink },
})
export default class SubHeader extends Vue {
  isPro: boolean = false;

  mounted() {
    if (this.$auth.loggedIn) {
      this.isPro = !!this.$auth.user.firstReference;
    }
  }
}
