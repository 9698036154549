import { Plugin } from '@nuxt/types';
import { RefreshScheme } from '@nuxtjs/auth-next';
import { initializeAxios } from '~/ts/utils/api';

const accessor: Plugin = (context) => {
  context.$axios.interceptors.request.use(
    (config) => {
      config.headers['Accept-Language'] = context.store.$i18n.localeProperties.code;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  context.$axios.interceptors.response.use(
    (res) => res,
    (err) => {
      const resData = err?.response?.data?.message;
      if (resData === 'Bad credentials.') {
        context.store.$auth.logout();
        (context.store.$auth.strategies.local as RefreshScheme).refreshToken.reset();
        (context.store.$auth.strategies.local as RefreshScheme).token.reset();
      }

      const status: number | undefined = err?.response?.status;
      if (['1', '2', '3'].includes(String(status).charAt(0))) {
        return Promise.resolve(err?.response);
      }

      return Promise.reject(err);
    },
  );

  initializeAxios(context.$axios);
};

export default accessor;
