const middleware = {}

middleware['guest'] = require('../middleware/guest.ts')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['isAdmin'] = require('../middleware/isAdmin.ts')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isDevBuild'] = require('../middleware/isDevBuild.ts')
middleware['isDevBuild'] = middleware['isDevBuild'].default || middleware['isDevBuild']

export default middleware
