
import { Component, Prop } from 'nuxt-property-decorator';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';
import { IconType } from '~/ts/interfaces/types';

import AdIcon from '~/components/icon/AdIcon.vue';
import AddIcon from '~/components/icon/AddIcon.vue';
import AmbassadorAddAreaIcon from '~/components/icon/Ambassador/AddAreaIcon.vue';
import AmbassadorAddPictureIcon from '~/components/icon/Ambassador/AddPictureIcon.vue';
import AmbassadorCommentPlaceIcon from '~/components/icon/Ambassador/CommentPlaceIcon.vue';
import AmbassadorCompleteProfileIcon from '~/components/icon/Ambassador/CompleteProfileIcon.vue';
import AmbassadorLeftQuoteIcon from '~/components/icon/Ambassador/LeftQuoteIcon.vue';
import AmbassadorLevel1Icon from '~/components/icon/Ambassador/Level1Icon.vue';
import AmbassadorLevel2Icon from '~/components/icon/Ambassador/Level2Icon.vue';
import AmbassadorLevel3Icon from '~/components/icon/Ambassador/Level3Icon.vue';
import AmbassadorLevel4Icon from '~/components/icon/Ambassador/Level4Icon.vue';
import AmbassadorLevel5Icon from '~/components/icon/Ambassador/Level5Icon.vue';
import AmbassadorLevel6Icon from '~/components/icon/Ambassador/Level6Icon.vue';
import AmbassadorLevel7Icon from '~/components/icon/Ambassador/Level7Icon.vue';
import AmbassadorLevel8Icon from '~/components/icon/Ambassador/Level8Icon.vue';
import AmbassadorRegisterIcon from '~/components/icon/Ambassador/RegisterIcon.vue';
import AmbassadorRightQuoteIcon from '~/components/icon/Ambassador/RightQuoteIcon.vue';
import AmbassadorUpdateAreaIcon from '~/components/icon/Ambassador/UpdateAreaIcon.vue';
import ArrowDownCircleIcon from '~/components/icon/ArrowDownCircleIcon.vue';
import ArrowRightIcon from '~/components/icon/ArrowRightIcon.vue';
import ArrowRightThinIcon from '~/components/icon/ArrowRightThinIcon.vue';
import ArrowUpWideShortIcon from '~/components/icon/ArrowUpWideShortIcon.vue';
import BriefcaseIcon from '~/components/icon/BriefcaseIcon.vue';
import CameraIcon from '~/components/icon/CameraIcon.vue';
import CameraPlusOutlineIcon from '~/components/icon/CameraPlusOutlineIcon.vue';
import CarasIcon from '~/components/icon/CarasIcon.vue';
import CarasOutlineIcon from '~/components/icon/CarasOutlineIcon.vue';
import CaravanIcon from '~/components/icon/CaravanIcon.vue';
import CheckIcon from '~/components/icon/CheckIcon.vue';
import ClockTimeNineOutlineIcon from '~/components/icon/ClockTimeNineOutlineIcon.vue';
import CogIcon from '~/components/icon/CogIcon.vue';
import CommentIcon from '~/components/icon/CommentIcon.vue';
import CompassIcon from '~/components/icon/CompassIcon.vue';
import CopyIcon from '~/components/icon/CopyIcon.vue';
import CrossIcon from '~/components/icon/CrossIcon.vue';
import CrownIcon from '~/components/icon/CrownIcon.vue';
import DownloadIcon from '~/components/icon/DownloadIcon.vue';
import EmailIcon from '~/components/icon/EmailIcon.vue';
import EmptyHeartIcon from '~/components/icon/EmptyHeartIcon.vue';
import FacebookIcon from '~/components/icon/FacebookIcon.vue';
import FilterIcon from '~/components/icon/FilterIcon.vue';
import FingerUpIcon from '~/components/icon/FingerUpIcon.vue';
import HeartIcon from '~/components/icon/HeartIcon.vue';
import HelpIcon from '~/components/icon/HelpIcon.vue';
import HomeIcon from '~/components/icon/HomeIcon.vue';
import LeftArrowIcon from '~/components/icon/LeftArrowIcon.vue';
import LikedCommentIcon from '~/components/icon/LikedCommentIcon.vue';
import LoadingIcon from '~/components/icon/LoadingIcon.vue';
import LocationArrowIcon from '~/components/icon/LocationArrowIcon.vue';
import MapPointIcon from '~/components/icon/MapPointIcon.vue';
import MoreIcon from '~/components/icon/MoreIcon.vue';
import PencilIcon from '~/components/icon/PencilIcon.vue';
import PlanetIcon from '~/components/icon/PlanetIcon.vue';
import PlayIcon from '~/components/icon/PlayIcon.vue';
import PlusThickIcon from '~/components/icon/PlusThickIcon.vue';
import PrintIcon from '~/components/icon/PrintIcon.vue';
import PrinterOutlineIcon from '~/components/icon/PrinterOutlineIcon.vue';
import ProximityIcon from '~/components/icon/ProximityIcon.vue';
import RoadVariantIcon from '~/components/icon/RoadVariantIcon.vue';
import RoundedArrowLeftIcon from '~/components/icon/RoundedArrowLeftIcon.vue';
import RoundedArrowRightIcon from '~/components/icon/RoundedArrowRightIcon.vue';
import RouteIcon from '~/components/icon/RouteIcon.vue';
import SaveIcon from '~/components/icon/SaveIcon.vue';
import ShareIcon from '~/components/icon/ShareIcon.vue';
import SignalIcon from '~/components/icon/SignalIcon.vue';
import SimpleArrowRightIcon from '~/components/icon/SimpleArrowRightIcon.vue';
import StarIcon from '~/components/icon/StarIcon.vue';
import ThumbUpIcon from '~/components/icon/ThumbUpIcon.vue';
import TickIcon from '~/components/icon/TickIcon.vue';
import TrashCanOutlineIcon from '~/components/icon/TrashCanOutlineIcon.vue';
import TravelIcon from '~/components/icon/TravelIcon.vue';
import TravelStepIcon from '~/components/icon/TravelStepIcon.vue';
import TrayArrowDownIcon from '~/components/icon/TrayArrowDownIcon.vue';
import TrayArrowUpIcon from '~/components/icon/TrayArrowUpIcon.vue';
import TriangleIcon from '~/components/icon/TriangleIcon.vue';
import TriangleOutlineIcon from '~/components/icon/TriangleOutlineIcon.vue';
import TwitterIcon from '~/components/icon/TwitterIcon.vue';
import UsersIcon from '~/components/icon/UsersIcon.vue';
import VanShuttleIcon from '~/components/icon/VanShuttleIcon.vue';
import ViewListIcon from '~/components/icon/ViewListIcon.vue';
import ZoomIcon from '~/components/icon/ZoomIcon.vue';

@Component({
  components: {
    AdIcon,
    AddIcon,
    AmbassadorAddAreaIcon,
    AmbassadorAddPictureIcon,
    AmbassadorCommentPlaceIcon,
    AmbassadorCompleteProfileIcon,
    AmbassadorLeftQuoteIcon,
    AmbassadorLevel1Icon,
    AmbassadorLevel2Icon,
    AmbassadorLevel3Icon,
    AmbassadorLevel4Icon,
    AmbassadorLevel5Icon,
    AmbassadorLevel6Icon,
    AmbassadorLevel7Icon,
    AmbassadorLevel8Icon,
    AmbassadorRegisterIcon,
    AmbassadorRightQuoteIcon,
    AmbassadorUpdateAreaIcon,
    ArrowDownCircleIcon,
    ArrowRightIcon,
    ArrowRightThinIcon,
    ArrowUpWideShortIcon,
    BriefcaseIcon,
    CameraIcon,
    CameraPlusOutlineIcon,
    CarasIcon,
    CarasOutlineIcon,
    CaravanIcon,
    CheckIcon,
    ClockTimeNineOutlineIcon,
    CogIcon,
    CommentIcon,
    CompassIcon,
    CopyIcon,
    CrossIcon,
    CrownIcon,
    DownloadIcon,
    EmailIcon,
    EmptyHeartIcon,
    FacebookIcon,
    FilterIcon,
    FingerUpIcon,
    HeartIcon,
    HelpIcon,
    HomeIcon,
    LeftArrowIcon,
    LikedCommentIcon,
    LoadingIcon,
    LocationArrowIcon,
    MapPointIcon,
    MoreIcon,
    PencilIcon,
    PlanetIcon,
    PlayIcon,
    PlusThickIcon,
    PrintIcon,
    PrinterOutlineIcon,
    ProximityIcon,
    RoadVariantIcon,
    RoundedArrowLeftIcon,
    RoundedArrowRightIcon,
    RouteIcon,
    SaveIcon,
    ShareIcon,
    SignalIcon,
    SimpleArrowRightIcon,
    StarIcon,
    ThumbUpIcon,
    TickIcon,
    TrashCanOutlineIcon,
    TravelIcon,
    TravelStepIcon,
    TrayArrowDownIcon,
    TrayArrowUpIcon,
    TriangleIcon,
    TriangleOutlineIcon,
    TwitterIcon,
    UsersIcon,
    VanShuttleIcon,
    ViewListIcon,
    ZoomIcon,
  },
})
export default class BaseIcon extends TwMergeMixin {
  @Prop({ default: '#E3444A', required: false }) iconColor!: string;
  @Prop({ default: true, required: false }) overrideColor!: boolean;
  @Prop({ required: true }) icon!: IconType;
  @Prop({ required: false }) backgroundColor!: string;
  @Prop({ default: 'h-full w-full', required: false }) size!: string;

  get defaultClass() {
    return 'w-4';
  }
}
