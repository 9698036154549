
import { Component, Vue } from 'nuxt-property-decorator';
import BaseCarousel from '~/components/base/BaseCarousel.vue';

@Component({
  components: { BaseCarousel },
})
export default class CarouselGuest extends Vue {
  private settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };
}
