// eslint-disable-next-line import/named
import { Context, NuxtAppOptions } from '@nuxt/types/app';
import { AxiosResponse } from 'axios';
import { NavigationGuardNext, Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import MetadataModule from '~/store/metadata';
import { Entrypoints } from '~/ts/config/entrypoints';
import { Metadata } from '~/ts/interfaces/types';
import Api from '~/ts/services/api/Api';

export function loadMetadataAsync(app: NuxtAppOptions, from: Route, to: Route): Promise<void> {
  const route = to.name?.split('___').shift() || '';

  if (!app.store?.$axios) return Promise.resolve();
  if (from.name === to.name) return Promise.resolve();

  const api = new Api(Entrypoints.Metadata);
  const params = api.formatParams([], { params: { ...to.query, ...to.params }, route });

  return app.store.$axios
    .get(`/api/${Entrypoints.Metadata}/seo?${params.join('&')}`)
    .then((response: AxiosResponse<Metadata>) => {
      const metadata = response.data;

      const metadataStore = getModule(MetadataModule, app.store);
      metadataStore.setTitle(metadata?.title || '');
      metadataStore.setDescription(metadata?.description || '');
      metadataStore.setKeywords(metadata?.keywords || '');
    });
}

export default (context: Context) => {
  context.app.router?.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    if (process.env.MAINTENANCE === 'true' && !to.name?.includes('maintenance'))
      return context.redirect(302, context.localePath('maintenance'));

    if (process.client) localStorage.setItem('lastVisitedPath', to.fullPath);
    loadMetadataAsync(context.app, from, to).then();
    next();
  });
};
