import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { store } from './index';

@Module({
  dynamic: true,
  name: 'toast',
  namespaced: true,
  stateFactory: true,
  store,
})
export default class ToastModule extends VuexModule {
  show: boolean = false;
  message: string = '';
  type: 'danger' | 'success' = 'success';
  timeout?: NodeJS.Timeout = undefined;

  @Mutation
  setShow(value: any) {
    this.show = value;
    clearTimeout(this.timeout);
  }

  @Mutation
  showMessage(props: { message: string; type: 'danger' | 'success' }) {
    this.message = props.message;
    this.type = props.type;
    this.show = true;

    clearTimeout(this.timeout);
  }

  @Mutation
  setIimeout(value: NodeJS.Timeout) {
    this.timeout = value;
  }
}
