import Api from './Api';
import { DirectoryCountry } from '~/ts/interfaces/types';
import { $axios } from '~/ts/utils/api';
import { Entrypoints } from '~/ts/config/entrypoints';

export class DirectoryCountryApi extends Api {
  constructor() {
    super(Entrypoints.DirectoryCountry);
  }

  async findBySlug(slug?: string, locale?: string): Promise<DirectoryCountry | null> {
    if (!slug || !locale) return null;

    return await $axios.$get(`api/${this.endpoint}/slug`, {
      params: {
        locale,
        slug,
      },
    });
  }
}
