import { Activity, PoiInformation, Revision, Trip, TripClass } from '~/ts/interfaces/types';

export default class SluggerService {
  public static formatSlug(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/[^A-Za-z0-9-]/g, '-')
      .replaceAll('--', '-')
      .toLowerCase();
  }

  static slugActivity(activity: Activity): string {
    return SluggerService.formatSlug(`${activity.title} ${activity.address.zipCode} ${activity.uuid}`);
  }

  static slugRevision(revision: Revision): string {
    return SluggerService.formatSlug(
      `${revision.name} ${revision.type.value} ${revision.address.zipCode} ${revision.pointOfInterest.uuid}`,
    );
  }

  static slugTrip(trip: TripClass | Trip): string {
    return SluggerService.formatSlug(`${trip.uuid}`);
  }

  static slugPoiInformation(poiInformation: PoiInformation): string {
    return SluggerService.formatSlug(`${poiInformation.title} ${poiInformation.zipCode} ${poiInformation.uuid}`);
  }

  static slugify(...values: Array<string>) {
    return SluggerService.formatSlug(values.join('-'));
  }

  static getUuid(slug?: string) {
    if (!slug) return null;

    return slug.slice(-36);
  }

  static getLastParam(slug?: string) {
    if (!slug) return null;

    return slug.split('-').pop();
  }
}
