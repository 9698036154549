import moment from 'moment';
import { Vue } from 'nuxt-property-decorator';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { confirmed, email, max, min, regex, required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'rules.required',
});

extend('confirmed', {
  ...confirmed,
  message: 'rules.confirmed',
});

extend('email', {
  ...email,
  message: 'rules.email',
});

extend('min', {
  ...min,
  message: 'rules.min',
});

extend('max', {
  ...max,
  message: 'rules.max',
});

const inputDateFormat = 'YYYY-MM-DD';
const dateFormat = 'DD/MM/YYYY';

extend('date', {
  message: 'rules.date.format:' + dateFormat,
  validate: (value) => {
    const regex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/gm;
    return regex.test(value);
  },
});

extend('date_before', {
  message: 'rules.date_before',
  params: ['target'],
  validate: (value, params) => {
    if (Array.isArray(params)) return true;

    let target = params.target;
    if (!target || !moment(target, inputDateFormat).isValid()) return true;

    target = moment(target, inputDateFormat);
    value = moment(value, inputDateFormat);
    return value.isBefore(target);
  },
});

extend('date_after', {
  message: 'rules.date_after',
  params: ['target'],
  validate: (value, params) => {
    if (Array.isArray(params)) return true;

    let target = params.target;
    if (!target || !moment(target, inputDateFormat).isValid()) return true;

    target = moment(target, inputDateFormat);
    value = moment(value, inputDateFormat);
    return value.isAfter(target);
  },
});

extend('password_confirm', {
  message: 'rules.incorrect_confirm_password',
  params: ['target'],
  validate: (value, params) => {
    if (Array.isArray(params)) return true;

    return params.target === value;
  },
});

extend('regex', regex);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
