import { Component, Vue } from 'nuxt-property-decorator';
import { CarasIcon, CarasLevelColor, CarasLevels, CarasLevelsLabel, CarasPercent } from '~/ts/config/enumerations';
import { IconType } from '~/ts/interfaces/types';

@Component({
  components: {},
})
export default class CarasMixin extends Vue {
  maxLevel = CarasLevels.length;

  getLevel(caras: number): number {
    let level = 0;

    while (caras >= CarasLevels[level] && level <= CarasLevels.length) {
      level++;
    }

    return level;
  }

  getLevelLabel(level: number): string {
    return String(this.$t(CarasLevelsLabel[level - 1]));
  }

  getlevelCaras(level: number): number {
    return CarasLevels[level - 1];
  }

  getLevelCustomPercent(level: number, caras: number): number {
    const previousCarasLevel = CarasLevels[level - 1];
    const nextCarasLevel = CarasLevels[level] ?? previousCarasLevel;

    const carasBetweenLevels = nextCarasLevel - previousCarasLevel || 1;
    const carasToNextLevel = nextCarasLevel - caras;

    const previousBarPercent = CarasPercent[level - 2] || 0;
    const nextBarPercent = CarasPercent[level - 1] ?? previousBarPercent;

    const percentToNextLevel = carasToNextLevel / carasBetweenLevels;
    const percentToNextBar = (nextBarPercent - previousBarPercent) * percentToNextLevel;

    return nextBarPercent - (level >= CarasLevels.length ? 0 : percentToNextBar);
  }

  getLevelPercent(level: number, caras: number): number {
    const previousCarasLevel = CarasLevels[level - 1];
    const nextCarasLevel = CarasLevels[level] ?? previousCarasLevel;

    const carasBetweenLevels = nextCarasLevel - previousCarasLevel || 1;
    const carasFromPreviousLevel = caras - previousCarasLevel;

    return (carasFromPreviousLevel / carasBetweenLevels) * 100;
  }

  getLevelMaxPercent(level: number) {
    return CarasPercent[level - 1];
  }

  getLevelHex(level: number) {
    return CarasLevelColor[level - 1];
  }

  getLevelIcon(level: number): IconType {
    return CarasIcon[level - 1];
  }

  getLevelColor(level: number): string {
    return CarasLevelColor[level - 1];
  }

  spaceNumber(x: number): string {
    if (!x) return String(x);
    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
