
import { Component, Prop } from 'nuxt-property-decorator';
import Container from '~/components/base/Container.vue';
import { TextMixin } from '~/ts/mixins/text';

@Component({
  components: { Container },
})
export default class ErrorLayout extends TextMixin {
  @Prop({}) error!: { message: string; path: string; statusCode: number };
}
