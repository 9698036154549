
import { Component, Prop, Vue } from 'nuxt-property-decorator';

@Component({
  components: {},
})
export default class MapPointIcon extends Vue {
  @Prop({ default: 'currentColor' }) color!: string;

  public static path: string = 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z';

  private get dataPath(): string {
    return MapPointIcon.path;
  }
}
