
import { Component } from 'nuxt-property-decorator';

import MetadatasMixin from '~/ts/mixins/metadata';
import BaseToast from '~/components/base/BaseToast.vue';
import TheHeader from '~/components/TheHeader.vue';

@Component({
  components: { BaseToast, TheHeader },
})
export default class Default extends MetadatasMixin {
  private gTagIframe: string = '<iframe src="\n' +
    'https://www.googletagmanager.com/ns.html?id=GTM-54NMRH9X"\n' +
    'height="0" width="0" style="display:none;visibility:hidden"></iframe>';
}
