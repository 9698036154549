import { IconType, Person } from '~/ts/interfaces/types';

export const CarasIcon: Array<IconType> = [
  'AmbassadorLevel1',
  'AmbassadorLevel2',
  'AmbassadorLevel3',
  'AmbassadorLevel4',
  'AmbassadorLevel5',
  'AmbassadorLevel6',
  'AmbassadorLevel7',
  'AmbassadorLevel8',
];
export const CarasLevelColor: Array<string> = [
  '#B1ACAA',
  '#5BC1F0',
  '#F4C100',
  '#9ACF11',
  '#E85554',
  '#AC42F9',
  '#EA7C1F',
  '#171717',
];
export const CarasLevels: Array<number> = [0, 1000, 5000, 15000, 30000, 50000, 100000, 1000000];
export const CarasLevelsLabel: Array<string> = [
  'ambassador_label.level1',
  'ambassador_label.level2',
  'ambassador_label.level3',
  'ambassador_label.level4',
  'ambassador_label.level5',
  'ambassador_label.level6',
  'ambassador_label.level7',
  'ambassador_label.level8',
];
export const CarasPercent: Array<number> = [10, 24, 36, 48, 60, 72, 84, 96];

export enum Constant {
  ATTRIBUTE_CATEGORY_BRAND = 'attribute_category_brand',
  ATTRIBUTE_CATEGORY_LABEL = 'attribute_category_label',
  ATTRIBUTE_CATEGORY_LANGUAGE = 'attribute_category_language',
  ATTRIBUTE_CATEGORY_PAYMENT = 'attribute_category_payment',
  ATTRIBUTE_CATEGORY_PLACE = 'attribute_category_place',
  ATTRIBUTE_CATEGORY_PRICE = 'attribute_category_price',
  ATTRIBUTE_CATEGORY_SERVICE = 'attribute_category_service',
  ATTRIBUTE_CATEGORY_SOURCES = 'attribute_category_sources',
  ATTRIBUTE_SECTION_AREA = 'area',
  ATTRIBUTE_SECTION_CAMPING = 'camping',
  ATTRIBUTE_SECTION_NATURE = 'nature',
  ATTRIBUTE_SECTION_PARKING = 'parking',
  ATTRIBUTE_SECTION_SEASIDE = 'seaside',
  ATTRIBUTE_SECTION_TOWN = 'town',
}

export enum Enumerations {
  AttributeCategoryPlace = 'attribute_category_place',
  AttributeCategoryPrice = 'attribute_category_price',
  AttributeCategoryService = 'attribute_category_service',
}

export enum MediaType {
  Media = 'media',
  Picto = 'picto',
}

export enum PointOfInterestTypes {
  Camping = 'camping',
  CaramapsHost = 'caramaps_host',
  Concession = 'concession',
  HighwayArea = 'highway_area',
  Parking = 'parking',
  PrivateArea = 'private_area',
  PumpStation = 'pump_station',
  ServiceArea = 'service_area',
  Spot = 'spot',
  TourismOffice = 'tourism_office',
}

export const ProfileFields: Array<keyof Person | string> = [
  'civility',
  'givenName',
  'familyName',
  'coverPicture',
  'instagram',
  'facebook',
  'birthDate',
  'address.zipCode',
  'address.cityName',
  'address.country',
  'telephone',
  'vehicleType',
  'description',
  'trips',
];

export enum RessourceURL {
  ITUNES = 'https://itunes.apple.com/fr/app/caramaps/id904605960',
  PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.adel.caramaps',
}

// eslint-disable-next-line no-use-before-define
export const RevisionCode: Array<RevisionCodeType> = [
  'camping',
  'caramaps_host',
  'concession',
  'highway_area',
  'parking',
  'private_area',
  'pump_station',
  'service_area',
  'spot',
  'tourism_office',
];

export type RevisionCodeType =
  | 'camping'
  | 'caramaps_host'
  | 'concession'
  | 'highway_area'
  | 'parking'
  | 'private_area'
  | 'pump_station'
  | 'service_area'
  | 'spot'
  | 'tourism_office';

export enum SocialMedia {
  CARAMAPS_BLOG = 'https://blog.caramaps.com/',
  CARAMAPS_FACEBOOK = 'https://facebook.com/caramaps',
  CARAMAPS_INSTAGRAM = 'https://instagram.com/caramaps',
  CARAMAPS_TWITTER = 'https://twitter.com/caramaps',
  FACEBOOK = 'https://facebook.com/',
  FACEBOOK_ME = 'https://fb.me/',
  GOOGLE = 'https://www.google.fr/search?q=',
  INSTAGRAM = 'https://instagram.com/',
  TWITTER = 'https://twitter.com/',
}

export enum StripePaymentRoute {
  ACCOUNT_PREMIUM = 'account_premium',
  ACTIVITY_PRO = 'activity_pro',
  POI_DISCOVERY = 'point_of_interest_discovery',
}
