
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
import { SocialMedia } from '~/ts/config/enumerations';
import { DirectoryCountry } from '~/ts/interfaces/types';
import { DirectoryCountryApi } from '~/ts/services/api/DirectoryCountryApi';
import SluggerService from '~/ts/services/SluggerService';

@Component({
  computed: {
    SluggerService() {
      return SluggerService;
    },
  },
})
export default class TheFooter extends Vue {
  @Prop({ default: false, required: false }) isSmall!: boolean;

  blog = SocialMedia.CARAMAPS_BLOG;

  twitter = SocialMedia.CARAMAPS_TWITTER;
  facebook = SocialMedia.CARAMAPS_FACEBOOK;
  instagram = SocialMedia.CARAMAPS_INSTAGRAM;

  directoryCountries: Array<DirectoryCountry> = [];
  directoryCountryApi = new DirectoryCountryApi();

  mounted() {
    this.getPage();

    this.fetchDirectoryCountry();
  }

  @Watch('$i18n.locale')
  fetchDirectoryCountry() {
    this.directoryCountryApi.findAll({ favorite: 1 }).then((dirCountryRes) => {
      this.directoryCountries = dirCountryRes['hydra:member'];
    });
  }

  updated() {
    this.getPage();
  }

  getPage() {
    window.removeEventListener('resize', this.addSlash);

    if (this.isSmall) {
      window.addEventListener('resize', this.addSlash);
      this.addSlash();
    }
  }

  addSlash() {
    const elements = document.querySelectorAll<HTMLDivElement>('.middle-slash');

    elements.forEach((element) => {
      const childs = element.children;

      for (let i = 1; i < childs.length; i++) {
        const child = childs.item(i);
        const previousChild = childs.item(i - 1);

        if (!child || !previousChild) continue;

        child.classList.remove('slash-before');
        if (previousChild.getBoundingClientRect().y === child.getBoundingClientRect().y) {
          child.classList.add('slash-before');
        }
      }
    });
  }
}
