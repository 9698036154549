import { CancelToken, CancelTokenSource } from 'axios';
import Api from './Api';
import { $axios } from '~/ts/utils/api';
import { Entrypoints } from '~/ts/config/entrypoints';
import {
  DeleteAccountForm,
  DictionnaryType,
  FavoriteOutput,
  PaginatedHydraResponse,
  Person,
  RandomPersonOutput,
} from '~/ts/interfaces/types';

export class PersonApi extends Api<Person> {
  constructor() {
    super(Entrypoints.Person);
  }

  async checkExists(queryParams: any, cancelToken?: CancelToken): Promise<boolean> {
    const people: Array<Person> = (
      await $axios
        .$get(`api/${this.endpoint}/exists`, {
          cancelToken,
          params: queryParams,
        })
        .catch(this.logError)
    )['hydra:member'].map((person: Person) => person);
    if (!queryParams['@id']) {
      return people.length > 0;
    }
    return people.some((person) => person['@id'] !== queryParams['@id']);
  }

  async requestPassword(email: string): Promise<any> {
    await $axios
      .$post(`api/password/request`, {
        email,
      })
      .catch(this.logError);
  }

  async resetPassword(params: Object): Promise<any> {
    await $axios.$post(`api/password/reset`, params).catch(this.logError);
  }

  async fetchFavorites(person: Person): Promise<Array<FavoriteOutput>> {
    if (!person) return [];
    return await $axios.$get(`api/people/favorites`).catch(this.logError);
  }

  async fetchFavoritesList(person: Person): Promise<Array<FavoriteOutput>> {
    if (!person) return [];
    return await $axios.$get(`api/people/favorites_list`).catch(this.logError);
  }

  async fetchBooleanFavorites(person: Person): Promise<DictionnaryType<boolean>> {
    if (!person) return {};
    return await $axios.$get(`api/people/favorites_boolean`).catch(this.logError);
  }

  async deleteAccount(params: DeleteAccountForm): Promise<any> {
    await $axios.$post('api/me/delete_account', params).catch(this.logError);
  }

  async getRank(uuid: string): Promise<any> {
    return await $axios.$get(`api/${this.endpoint}/${uuid}/rank`).catch(this.logError);
  }

  async getRandomPeople(num: number = 200): Promise<PaginatedHydraResponse<RandomPersonOutput>> {
    return await $axios.$get(`api/${this.endpoint}/random`, { params: { amount: num } }).catch(this.logError);
  }

  async changeLocale(person: Person, locale: string) {
    await $axios.$put(`api/people/${person.uuid}/locale`, { locale }).catch(this.logError);
  }

  substituteSearch(query: string, source?: CancelTokenSource | string): Promise<PaginatedHydraResponse<Person>> {
    source = this.cancelAndGetSource(source);
    return $axios.$get(`api/people/substitute`, { cancelToken: source?.token, params: { deletedAt: false, query } });
  }
}
