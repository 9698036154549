
import { Component, Prop } from 'nuxt-property-decorator';
import { AdService } from '~/ts/services/AdService';
import { WebAd } from '~/ts/interfaces/types';
import { MediaMixin } from '~/ts/mixins/media';
import BaseIcon from '~/components/base/BaseIcon.vue';

@Component({
  components: { BaseIcon },
})
export default class AdPopup extends MediaMixin {
  @Prop({ required: true }) ad!: WebAd;

  displayCross: boolean = false;

  mounted() {
    setTimeout(() => {
      this.displayCross = true;
    }, 3 * 1000);
  }

  onAdClick() {
    AdService.onAdClick(this.ad);
  }

  onCrossClick(event: Event) {
    if (!this.displayCross) return;

    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    this.$emit('close');
  }
}
