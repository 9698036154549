
import { Component, Vue } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';

const toast = namespace('toast');
@Component({})
export default class BaseToast extends Vue {
  get showToast() {
    return this.show;
  }

  set showToast(value) {
    this.setShow(value);
  }

  @toast.Mutation
  setShow!: (value: any) => void;

  @toast.State
  message!: string | null;

  @toast.State
  show!: boolean | null;

  @toast.State
  type!: string | null;

  onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === 'Escape') this.showToast = false;
  }
}
