
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { RawLocation } from 'vue-router';

@Component({
  components: {},
})
export default class DrawerRightLink extends Vue {
  @Prop({ required: false }) private url!: string | undefined | RawLocation;
  @Prop({ required: true }) private name!: string;
}
