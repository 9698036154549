import moment from 'moment'

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/de'
import 'moment/locale/nl'
import 'moment/locale/es'
import 'moment/locale/pt'

import 'moment-timezone'

moment.locale('fr')

moment.tz.setDefault('Europe/Paris')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
