import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { store } from './index';

@Module({
  dynamic: true,
  name: 'metadata',
  namespaced: true,
  stateFactory: true,
  store,
})
export default class MetadataModule extends VuexModule {
  description: string = '';
  title: string = '';
  keywords: string | null = null;

  @Mutation
  setDescription(value: string) {
    this.description = value;
  }

  @Mutation
  setTitle(value: string) {
    this.title = value;
  }

  @Mutation
  setKeywords(value: string | null) {
    this.keywords = value;
  }
}
