
import { Component, VModel, Vue } from 'nuxt-property-decorator';
import DrawerRightLink from '~/components/DrawerRightLink.vue';
import { CustomLocaleObject } from '~/ts/interfaces/types';
import UserService from '~/ts/services/UserService';

@Component({
  components: { DrawerRightLink },
})
export default class DrawerRight extends Vue {
  @VModel({ type: Boolean }) show!: boolean;

  isPro: boolean = false;

  mounted() {
    if (this.$auth.loggedIn) this.isPro = !!this.$auth.user.firstReference;
  }

  get availableLocales() {
    return (this.$i18n.locales as CustomLocaleObject[]).filter((i: CustomLocaleObject) => i.code !== this.$i18n.locale);
  }

  get isLoggedIn(): boolean {
    return this.$auth.loggedIn;
  }

  get isAdmin(): boolean {
    return this.isLoggedIn && UserService.isAdmin(this.$auth.user);
  }

  async logout() {
    console.log('logout');
    await this.$auth.logout();
  }

  navigateToAdmin() {
    window.open('http://localhost/admin/members', '_blank');
  }
}
