// eslint-disable-next-line
import { Plugin } from '@nuxt/types';

const accessor: Plugin = ({ app, $auth }) => {
  $auth.onRedirect((to) => {
    return app.localePath(to);
  });
};

export default accessor;
