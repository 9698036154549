import { Component, Vue } from 'nuxt-property-decorator';
import { Entrypoints } from '../config/entrypoints';
import { Activity, ImageFilterType, Media, Revision } from '~/ts/interfaces/types';

@Component({
  components: {},
})
export class MediaMixin extends Vue {
  /**
   * The available image filter types.
   *
   * - `full`: 1920x1080
   * - `lg`: 700x700
   * - `md`: 300x300
   * - `full_banner`: 1920x200
   * - `watermark`: Watermark filter
   */
  getMediaSrc<T extends { [key: string]: any }>(
    item: T | null,
    attribute: keyof T | string,
    filter: ImageFilterType,
  ): string {
    const media = this.getMedia(item, attribute);
    if (!media) return this.handlePlaceholders(item);

    const filterQuery = filter ? `?filter=${filter}` : '';

    if (media.contentUrl) return `${media.contentUrl}${filterQuery}`;
    if (media['@id']) return `${this.$axios.defaults.baseURL}${media['@id']}/download${filterQuery}`;
    if (media.uuid)
      return `${this.$axios.defaults.baseURL}/api/${Entrypoints.Media}/${media.uuid}/download${filterQuery}`;

    return this.handlePlaceholders(item);
  }

  getStaticPictoSrc(originalName: string | null): string {
    if (!originalName) return this.handlePlaceholders(null);

    const baseName = originalName.replace(/\.(webp|png)$/, '');
    let pictoUrl: string;

    try {
      // Essayer d'abord de charger le fichier PNG
      pictoUrl = require(`~/static/picto/${baseName}.png`);
    } catch (error) {
      try {
        // Si le PNG n'existe pas, essayer de charger le fichier WEBP
        pictoUrl = require(`~/static/picto/${baseName}.webp`);
      } catch (error) {
        // Si ni PNG ni WEBP n'existent, utiliser le picto par défaut
        console.warn(`Picto non trouvé pour ${originalName}, utilisation du picto par défaut.`);
        pictoUrl = require('~/static/picto/default_picto.webp');
      }
    }

    return pictoUrl;
  }

  getMedia<T extends { [key: string]: any }>(item: T | null, attribute: keyof T | string): Media | null {
    if (!item) return null;

    const media: Media | Array<Media> | null = ((attribute && attribute !== '' && (attribute as any) in item
      ? item[attribute]
      : attribute === ''
      ? item
      : null) || null) as Media | Array<Media> | null;
    if (!media) return null;

    return Array.isArray(media) ? media[0] : media;
  }

  async openMedia(media: Media | null) {
    if (!media) return;

    const response = await this.$axios.get(media['@id'] + '/download', {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: media.mimeType || 'application/pdf' });
    const data = URL.createObjectURL(blob);

    window.open(data, '_blank');
  }

  handlePlaceholders(item: any): string {
    if (!item) return '';

    if ('pointOfInterest' in item) {
      // revision
      return this.getRevisionPlaceholder(item);
    }

    return '';
  }

  getRevisionPlaceholder(revision: Revision) {
    return require('static/' + revision.type.code + '_placeholder.webp');
  }

  getActivityMediaSrc(activity: Activity, filter: ImageFilterType = 'full'): string {
    return (
      this.getMediaSrc(activity, 'mainPicture', filter) ||
      this.getMediaSrc(activity, 'medias', filter) ||
      '/activity_default.webp'
    );
  }
}
