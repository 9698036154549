
import { Component, Vue } from 'nuxt-property-decorator';
import BaseIcon from '~/components/base/BaseIcon.vue';
import { Entrypoints } from '~/ts/config/entrypoints';
import { CustomLocaleObject, DictionnaryType } from '~/ts/interfaces/types';
import Api from '~/ts/services/api/Api';
import { PersonApi } from '~/ts/services/api/PersonApi';
import UserService from '~/ts/services/UserService';

@Component({
  components: { BaseIcon },
})
export default class LangSwitcher extends Vue {
  private personApi: PersonApi = new PersonApi();
  private translationApi = new Api<DictionnaryType>(Entrypoints.Translation);

  isDebug = false;

  get availableLocales() {
    const locales = [...this.$i18n.locales] as Array<CustomLocaleObject>;

    if (this.isAdmin && !locales.find((locale) => locale.code === 'debug')) {
      locales.push({
        code: 'debug',
        file: 'debug.svg',
        name: 'Debug',
      });
    }

    return locales.filter((locale) => locale.code !== this.$i18n.locale);
  }

  get isLoggedIn(): boolean {
    return this.$auth.loggedIn;
  }

  get isAdmin(): boolean {
    return this.isLoggedIn && UserService.isAdmin(this.$auth.user);
  }

  updated() {
    if (this.isLoggedIn && this.$auth.user.locale !== this.$i18n.locale) {
      this.personApi.changeLocale(this.$auth.user, this.$i18n.locale).then();
    }
  }

  async onSwitchLocale(locale: string) {
    if (locale === 'debug') {
      if (this.isDebug) {
        this.$i18n.setLocaleMessage(this.$i18n.locale, await this.translationApi.findByUuid(this.$i18n.locale));
        this.isDebug = false;
      } else {
        (this.$i18n.locales as Array<CustomLocaleObject>).forEach((locale) => {
          this.$i18n.setLocaleMessage(locale.code, {});
        });
        this.isDebug = true;
      }
      return;
    }

    if (this.isLoggedIn && this.$auth.user.locale !== this.$i18n.locale) {
      this.personApi.changeLocale(this.$auth.user, this.$i18n.locale).then();
    }

    await this.$router.push(this.switchLocalePath(locale));
  }
}
