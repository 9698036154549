
import { Component } from 'nuxt-property-decorator';
import LangSwitcher from '~/components/LangSwitcher.vue';
import DrawerRight from '~/components/DrawerRight.vue';
import DrawerLeft from '~/components/DrawerLeft.vue';
import SubHeader from '~/components/SubHeader.vue';
import DropdownHeaderLink from '~/components/DropdownHeaderLink.vue';
import CarasMixin from '~/ts/mixins/caras';
import BaseIcon from '~/components/base/BaseIcon.vue';
import UserService from '~/ts/services/UserService';

@Component({
  components: { BaseIcon, DrawerLeft, DrawerRight, DropdownHeaderLink, LangSwitcher, SubHeader },
})
export default class TheHeader extends CarasMixin {
  public showDrawerLeft: boolean = false;
  public showDrawerRight: boolean = false;
  public adminURL: string = process.env.baseURL + '/admin/members';

  public menu = [
    {
      html: 'header.view_map',
      to: 'map',
    },
    {
      html: 'header.activities_map',
      to: 'activities-map',
    },
    {
      html: 'header.theme_travels',
      to: 'trips-home',
    },
    {
      html: 'header.download_app',
      to: 'application',
    },
    {
      html: 'header.pros',
      to: 'pro',
    },
  ];

  get level() {
    if (!this.$auth.user) return 0;

    return this.getLevel(this.$auth.user.caras || 0);
  }

  get isLoggedIn(): boolean {
    return this.$auth.loggedIn;
  }

  get isAdmin(): boolean {
    return this.isLoggedIn && UserService.isAdmin(this.$auth.user);
  }

  async logout() {
    await this.$auth.logout();
  }
}
