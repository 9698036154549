
import { Component } from 'nuxt-property-decorator';

import MetadatasMixin from '~/ts/mixins/metadata';
import BaseToast from '~/components/base/BaseToast.vue';
import CarouselGuest from '~/components/CarouselGuest.vue';

@Component({
  auth: false,
  components: { BaseToast, CarouselGuest },
})
export default class LoginLayout extends MetadatasMixin {
  private gTagIframe: string = '<iframe src="\n' +
    'https://www.googletagmanager.com/ns.html?id=GTM-54NMRH9X"\n' +
    'height="0" width="0" style="display:none;visibility:hidden"></iframe>';
}
