
import { Component, Vue } from 'vue-property-decorator';
import { Prop } from 'nuxt-property-decorator';

@Component({})
export default class AmbassadorLevel2Icon extends Vue {
  @Prop({ default: '#5bc1f0' }) backgroundColor!: string;
  @Prop({ default: '#3e3c38' }) color!: string;

  mounted() {
    this.$emit('bg-color', this.backgroundColor);
  }
}
