export enum Entrypoints {
  Activity = 'activities',
  ActivityMedia = 'activity_medias',
  ActivityType = 'activity_types',
  Attribute = 'attributes',
  Award = 'awards',
  CamperVanBrand = 'camper_van_brands',
  CamperVanType = 'camper_van_types',
  Comment = 'comments',
  Config = 'configs',
  Country = 'countries',
  CurrencyType = 'currency_types',
  Destination = 'destinations',
  Direction = 'direction',
  DirectoryCountry = 'directory_countries',
  DirectoryDepartment = 'directory_departments',
  Enumerations = 'enumerations',
  KnownType = 'known_types',
  Locale = 'locales',
  Media = 'media',
  MediaKit = 'media_kits/contact',
  Metadata = 'metadata',
  OwnershipRequest = 'ownership_requests',
  ParkingTime = 'parking_times',
  Partners = 'partners',
  Payment = 'payments',
  PaymentType = 'payment_types',
  Person = 'people',
  PersonDetail = 'detail_person_views',
  PersonPointOfInterest = 'person_point_of_interest',
  PersonStat = 'person_stat_views',
  Picture = 'pictures',
  PoiType = 'point_of_interest_types',
  PointOfInterest = 'point_of_interests',
  PointOfInterestComment = 'point_of_interest_comments',
  PressKit = 'press_kits',
  PressRelease = 'press_releases',
  PressStatement = 'press_statements',
  PressVisualKit = 'press_visualkits',
  PromoCode = 'promo_codes',
  Revision = 'revisions',
  RevisionAttribute = 'revision_attributes',
  Step = 'steps',
  Stripe = 'stripe',
  TeamMember = 'team_members',
  Translation = 'translations',
  Trip = 'trips',
  TripCategory = 'trip_categories',
  TripComment = 'trip_comments',
  TripCountry = 'trip_countries',
  VehicleType = 'vehicle_types',
  Weather = 'weather',
  WebAd = 'webs',
}
