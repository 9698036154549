import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { twMerge } from 'tailwind-merge';
import { ClassNameValue } from 'tailwind-merge/dist/lib/tw-join';

@Component({
  components: {},
})
export class TwMergeMixin extends Vue {
  @Prop({ default: undefined }) classname!: ClassNameValue;

  get defaultClass(): ClassNameValue {
    return null;
  }

  twMerge = twMerge;

  get mergedClass() {
    return this.twMerge(this.defaultClass, this.classname);
  }
}
