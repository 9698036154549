
import { Component, Prop } from 'nuxt-property-decorator';
import BaseIcon from '~/components/base/BaseIcon.vue';
import { TwMergeMixin } from '~/ts/mixins/tw_merge';

@Component({
  components: { BaseIcon },
})
export default class BaseButton extends TwMergeMixin {
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isBorder!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: false }) blockOnLoading!: boolean;
  @Prop({ default: false }) absolute!: boolean;
  @Prop({ default: 'red', required: false }) borderColor!: string;

  get defaultClass() {
    return [
      'inline-block h-fit rounded-3xl font-bold px-5 py-1 text-sm',
      this.isBorder ? 'border text-red bg-white hover:bg-[#F7E7EA]' : 'bg-red text-white hover:bg-[#BB393E]',
      this.borderColor ? 'border-' + this.borderColor : 'border-red',
      this.disabled ? 'cursor-not-allowed disabled:opacity-50' : 'cursor-pointer hover:shadow-lg',
      this.absolute ? 'absolute' : 'relative',
    ];
  }

  onEvent(type: string, event: any) {
    if (this.disabled) return;

    this.$emit(type, event);
  }

  onKeyUp(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.key === 'Enter') this.$emit('click', event);

    this.$emit('keyup', event);
  }
}
