
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import BaseIcon from '~/components/base/BaseIcon.vue';

@Component({
  components: { BaseIcon },
})
export default class BaseCarousel extends Vue {
  @Prop({ default: 0, required: true }) itemsLength!: number;
  @Prop({ default: false, required: false }) autoplay!: boolean;
  @Prop({ default: 5000, required: false }) autoplaySpeed!: number;
  @Prop({ default: 6, required: false }) perPage!: number;
  @Prop({ required: false }) private settings!: object | null;
  @Prop({ default: false, required: false }) arrows!: boolean;

  private defaultSettings = {
    arrows: false,
    autoplay: this.autoplay,
    autoplaySpeed: this.autoplaySpeed,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          initialSlide: 2,
          slidesToScroll: 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: this.perPage - 1,
    slidesToShow: this.perPage,
  };

  get carousel(): null | undefined | any {
    return this.$refs.carousel;
  }

  onArrowClick(next: boolean = false) {
    if (!this.carousel) return;

    next ? this.carousel.next() : this.carousel.prev();
  }
}
