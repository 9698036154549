import moment from 'moment';
import { Person } from '~/ts/interfaces/types';

export default class UserService {
  static isGranted(user: Person, roles: Array<string> | string): boolean {
    if (!user || user.roles === undefined) {
      return false;
    }

    if (Array.isArray(roles)) return user.roles.some((role: string) => roles.includes(role));
    return user.roles.includes(roles);
  }

  static isPremium(user: Person): boolean {
    if (!user) return false;
    return moment(user.endPremiumDate).isAfter(moment());
  }

  static isAdmin(user: Person): boolean {
    return this.isGranted(user, 'ROLE_ADMIN');
  }
}
